export enum Lv1HealthFactor {
  Competition = "Competition",
  ESG = "ESG",
  "Earnings Power" = "Earnings Power",
  Management = "Management",
  Reputation = "Reputation",
  Systemic = "Systemic",
}

export const LV1_HEALTH_FACTORS: Lv1HealthFactor[] = [
  Lv1HealthFactor.Competition,
  Lv1HealthFactor["Earnings Power"],
  Lv1HealthFactor.Management,
  Lv1HealthFactor.Reputation,
  Lv1HealthFactor.ESG,
  Lv1HealthFactor.Systemic,
];
