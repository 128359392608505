import "@/styles/globals.css";

import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import React, { useRef } from "react";
import type { ToastContainerProps } from "react-toastify";

import useIsClient from "@/hooks/useIsClient";
import ABTesting from "@/layouts/ABTesting";
import AppInsightsWrapper from "@/layouts/AppInsightsWrapper";
import CookiesWrapper from "@/layouts/CookiesWrapper";
import { ErrorBoundary } from "@/layouts/ErrorBoundary";
import { MetadataProvider } from "@/layouts/MetadataProvider";
import MsalLayoutWrapper from "@/layouts/MsalLayoutWrapper";
import ReduxStore from "@/layouts/ReduxStore";
import { isProd } from "@/util/app";
import { domine, ibm_plex_mono, roboto, source_sans } from "@/util/fonts";

const MW = {
  DevToolbox: dynamic(() =>
    import("@/components/ui/devToolbox").then((module) => module.DevToolbox)
  ),
  FCRoot: dynamic(() =>
    import("@/components/ui/FCRoot").then((module) => module.default)
  ),
  Fingerprint: dynamic(() =>
    import("@/layouts/Fingerprint").then((module) => module.default)
  ),
  HotkeyManager: dynamic(() =>
    import("@/layouts/HotkeyManager").then((module) => module.HotkeyManager)
  ),
  LoadingScreen: dynamic(() =>
    import("@/layouts/LoadingScreenLayout").then((module) => module.default)
  ),
  PageTracking: dynamic(() =>
    import("@/layouts/PageTracking").then((module) => module.default)
  ),
  ToastContainer: dynamic<ToastContainerProps>(() =>
    import("@/layouts/ToastContainer").then((module) => module.default)
  ),
  Tracking: dynamic(
    () => import("@/layouts/Tracking").then((module) => module.default),
    {
      ssr: false,
    }
  ),
};

const MyApp = (props: AppProps) => {
  const isClient = useIsClient();
  const { Component, pageProps } = props;
  const children = <Component {...pageProps} />;
  const childrenSnapshot = useRef(children);
  return (
    <main
      className={`${roboto.variable} ${domine.variable} ${ibm_plex_mono.variable} ${source_sans.variable} font-sans`}
    >
      <ErrorBoundary {...props}>
        <MetadataProvider
          Component={Component}
          pageProps={pageProps}
          defaultTitle="Foliko by SynerAI"
        />
        <ReduxStore>
          {/* Middleware SSR-disabled boundary */}
          {isClient ? (
            <ABTesting>
              <AppInsightsWrapper>
                <CookiesWrapper>
                  <MsalLayoutWrapper>
                    {children}
                    <MW.FCRoot />
                    <MW.Fingerprint />
                    <MW.PageTracking />
                    <MW.ToastContainer />
                    <MW.LoadingScreen />
                    <MW.DevToolbox />
                    <MW.HotkeyManager />
                    {isProd && <MW.Tracking />}
                  </MsalLayoutWrapper>
                </CookiesWrapper>
              </AppInsightsWrapper>
            </ABTesting>
          ) : (
            <>{childrenSnapshot.current}</>
          )}
        </ReduxStore>
      </ErrorBoundary>
    </main>
  );
};

export default MyApp;
