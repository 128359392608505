import React, { lazy, PropsWithChildren, Suspense } from "react";
import { shallowEqual } from "react-redux";

import { useAppSelector } from "@/hooks";
import { ForkUrl } from "@/util/app";

const Provider = lazy(() => import("@synerai/react-experiment"));

const ABTesting = ({ children }: PropsWithChildren) => {
  const { isStaff } = useAppSelector((state) => state.user, shallowEqual);
  const { deviceId } = useAppSelector((state) => state.site, shallowEqual);
  return (
    <Suspense fallback={<>{children}</>}>
      <Provider
        forkUrl={ForkUrl}
        namespace="foliko"
        userProfile={{
          staff: String(isStaff),
        }}
        deviceId={deviceId}
      >
        {children}
      </Provider>
    </Suspense>
  );
};

export default ABTesting;
