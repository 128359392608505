import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { lazy, PropsWithChildren, Suspense } from "react";

import { LoadingAnimation } from "@/components/ui/LoadingAnimation";
import { getRouteType } from "@/util/Routes";

const Provider = lazy(() => import("./MsalLayout"));
const AxiosInit = dynamic(
  () => import("./LazyAxiosInit").then((mod) => mod.default),
  { ssr: false }
);

const MsalLayoutWrapper = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const routeType = getRouteType(router.pathname);
  const Fallback =
    routeType == "Authenticated" ? <LoadingAnimation /> : <>{children}</>;

  return (
    <>
      <AxiosInit />
      {routeType == "Anonymous" ? (
        Fallback
      ) : (
        <Suspense fallback={Fallback}>
          <Provider>{children}</Provider>
        </Suspense>
      )}
    </>
  );
};

export default MsalLayoutWrapper;
