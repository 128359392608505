import { configureStore } from "@reduxjs/toolkit";
import dynamic from "next/dynamic";
import { PropsWithChildren } from "react";
import { Provider } from "react-redux";

import type {
  ArticleSliceModel,
  CompanyProfileSliceModel,
  HomeSliceModel,
  LeaderboardSliceModel,
  PortfolioSliceModel,
  ProductTourSliceModel,
  SectorSliceModel,
  SettingsSliceModel,
  SiteSliceModel,
  SnapshotSliceModel,
  SubSliceModel,
  UserSliceModel,
} from "@/model/redux";
import {
  articleSliceInitialState,
  companyProfileSliceInitialState,
  homeSliceInitialState,
  leaderboardSliceInitialState,
  portfolioSliceInitialState,
  sectorSliceInitialState,
  settingsSliceInitialState,
  siteSliceInitialState,
  snapshotSliceInitialState,
  subSliceInitialState,
  tourSliceInitialState,
  userSliceInitialState,
} from "@/model/reduxInit";
import { makeDefaultSlice } from "@/slice/DefaultSlice";

const ReduxSliceInjector = dynamic(
  () => import("./ReduxSliceInjector").then((mod) => mod.default),
  { ssr: false }
);

export const reduxStore = configureStore({
  reducer: {
    articles: makeDefaultSlice<ArticleSliceModel>(articleSliceInitialState),
    companyProfile: makeDefaultSlice<CompanyProfileSliceModel>(
      companyProfileSliceInitialState
    ),
    home: makeDefaultSlice<HomeSliceModel>(homeSliceInitialState),
    leaderBoard: makeDefaultSlice<LeaderboardSliceModel>(
      leaderboardSliceInitialState
    ),
    portfolio: makeDefaultSlice<PortfolioSliceModel>(
      portfolioSliceInitialState
    ),
    productTour: makeDefaultSlice<ProductTourSliceModel>(tourSliceInitialState),
    sector: makeDefaultSlice<SectorSliceModel>(sectorSliceInitialState),
    settings: makeDefaultSlice<SettingsSliceModel>(settingsSliceInitialState),
    site: makeDefaultSlice<SiteSliceModel>(siteSliceInitialState),
    snapshot: makeDefaultSlice<SnapshotSliceModel>(snapshotSliceInitialState),
    sub: makeDefaultSlice<SubSliceModel>(subSliceInitialState),
    user: makeDefaultSlice<UserSliceModel>(userSliceInitialState),
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

const ReduxStore = ({ children }: PropsWithChildren) => (
  <Provider store={reduxStore}>
    <ReduxSliceInjector reduxStore={reduxStore} />
    {children}
  </Provider>
);

export default ReduxStore;
