import type { NextComponentType, NextPageContext } from "next";
import dynamic from "next/dynamic";
import { PropsWithChildren } from "react";
import { deserializeError } from "serialize-error";

const ErrorBoundaryHandler = dynamic(() =>
  import("./ErrorBoundaryHandler").then((mod) => mod.default)
);

type ErrorBoundaryProps = {
  Component: NextComponentType<NextPageContext, any, any>;
  pageProps: any;
} & PropsWithChildren;

export const ErrorBoundary = ({
  Component,
  pageProps,
  children,
}: ErrorBoundaryProps) =>
  pageProps.err ? (
    <>
      <Component {...pageProps} />
      <ErrorBoundaryHandler err={deserializeError(pageProps.err)} />
    </>
  ) : (
    <>{children}</>
  );
