import Head from "next/head";
import { useRouter } from "next/router";
import { NextComponentType, NextPageContext } from "next/types";

import { FolikoPage } from "@/model/page";
import { BaseUrl } from "@/util/app";
import {
  makeArticleFragment,
  makeDescriptionFragment,
  makeThumbnailFragment,
  makeTitleFragment,
  makeUrlFragment,
} from "@/util/metadata";

interface Props {
  Component: NextComponentType<NextPageContext, any, any>;
  defaultTitle?: string;
  pageProps: {};
}

export const MetadataProvider = ({
  Component,
  pageProps,
  defaultTitle = "",
}: Props) => {
  const router = useRouter();
  const page = Component as FolikoPage;

  if (!page.getMetadata) {
    return null;
  }

  const {
    pagePublishDate,
    pageTitle,
    pageDescription,
    pageThumbnail,
    pageUrl,
  } = page.getMetadata(pageProps);

  const title = pageTitle ? pageTitle({ pageProps, router }) : defaultTitle;
  const url = pageUrl ? pageUrl({ pageProps, router }) : "";
  const publishDate = pagePublishDate
    ? pagePublishDate({ pageProps, router })
    : undefined;
  const description = pageDescription
    ? pageDescription({ pageProps, router })
    : "";
  const thumbnail = pageThumbnail ? pageThumbnail({ pageProps, router }) : "";

  return (
    <Head key="metadata-provider">
      <meta property="og:type" content="website" />
      <meta property="twitter:domain" content={BaseUrl} />
      {title && makeTitleFragment(title)}
      {description && makeDescriptionFragment(description)}
      {url && makeUrlFragment(url)}
      {thumbnail && makeThumbnailFragment(thumbnail)}
      {publishDate &&
        makeArticleFragment(title, description, publishDate, url, thumbnail)}
    </Head>
  );
};
