import React, { lazy, PropsWithChildren, Suspense } from "react";

const Provider = lazy(() => import("./AppInsights"));

// withAITracking from the @microsoft/applicationinsights-react-js library adds a div wrapper to the app
const Fallback = ({ children }: PropsWithChildren) => (
  <div className="absolute inset-0">{children}</div>
);

const AppInsightsWrapper = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Fallback>{children}</Fallback>}>
    <Provider>{children}</Provider>
  </Suspense>
);

export default AppInsightsWrapper;
