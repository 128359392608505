import { format } from "date-fns";
import React from "react";
import { jsonLdScriptProps } from "react-schemaorg";
import { AnalysisNewsArticle, WithContext } from "schema-dts";

export const makeTitleFragment = (title: string) => (
  <>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="twitter:title" content={title} />
  </>
);

export const makeDescriptionFragment = (description: string) => (
  <>
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="twitter:description" content={description} />
  </>
);

export const makeUrlFragment = (url: string) => (
  <>
    <meta property="og:url" content={url} />
    <meta property="twitter:url" content={url} />
    <link rel="canonical" href={url} key="canonical" />
  </>
);

export const makeThumbnailFragment = (thumbnail: string) => (
  <>
    <meta property="og:image" content={thumbnail} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content={thumbnail} />
  </>
);

export const makeArticleFragment = (
  title: string,
  description: string,
  publishDate: Date,
  url: string,
  thumbnail: string
) => {
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "AnalysisNewsArticle",
    articleBody: description,
    author: {
      "@type": "Organization",
      name: "Team SynerAI",
      url: "https://synerai.com/",
    },
    datePublished: format(publishDate, "yyyy-MM-dd"),
    headline: title,
    image: [thumbnail],
    mainEntityOfPage: url,
    publisher: {
      "@type": "Organization",
      logo: {
        "@type": "ImageObject",
        url: "/logo.webp",
      },
      name: "Team SynerAI",
    },
  } as WithContext<AnalysisNewsArticle>;
  return <script {...jsonLdScriptProps<AnalysisNewsArticle>(articleSchema)} />;
};
