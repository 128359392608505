export const tourPageInitialPropsGen = () => {
  return {
    completed: false,
    modalShown: false,
    stateProps: {
      continuous: true,
      key: "2022-11-21T01:17:11.022Z",
      loading: false,
      run: false,
      stepIndex: 0,
    },
    tourLength: 0,
  };
};

export const initialTourObject = {
  companyProfile: tourPageInitialPropsGen(),
  home: tourPageInitialPropsGen(),
  portfolio: tourPageInitialPropsGen(),
  sectorReview: tourPageInitialPropsGen(),
};

export const testSampleTourObject = {
  companyProfile: {
    completed: true,
    modalShown: true,
    stateProps: {
      continuous: true,
      key: "2022-11-21T01:17:11.022Z",
      loading: false,
      run: false,
      stepIndex: 11,
    },
    tourLength: 11,
  },
  home: {
    completed: true,
    modalShown: true,
    stateProps: {
      continuous: true,
      key: "2022-11-21T01:17:11.022Z",
      loading: false,
      run: false,
      stepIndex: 4,
    },
    tourLength: 4,
  },
  portfolio: {
    completed: false,
    modalShown: false,
    stateProps: {
      continuous: true,
      key: "2022-11-21T01:17:11.022Z",
      loading: false,
      run: false,
      stepIndex: 0,
    },
    tourLength: 4,
  },
  sectorReview: {
    completed: false,
    modalShown: true,
    stateProps: {
      continuous: true,
      key: "2022-11-21T01:17:11.022Z",
      loading: false,
      run: false,
      stepIndex: 5,
    },
    tourLength: 8,
  },
};
