import React from "react";

interface Props {
  animate?: boolean;
  className?: string;
  message?: string;
}

export const LoadingAnimation = ({
  message = "Loading",
  animate = true,
  className = "text-base font-semibold",
}: Props) => {
  return (
    <div
      data-testid="loading-animation"
      className="absolute inset-0 flex items-center justify-center space-x-2 select-none"
    >
      <div className={className}>{message}</div>
      {animate && (
        <div className={`w-8 h-6 -mt-3 ${className}`}>
          <svg
            version="1.1"
            id="L5"
            xmlns="https://www.w3.org/2000/svg"
            xmlnsXlink="https://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            xmlSpace="preserve"
            fill="currentColor"
          >
            <circle stroke="none" cx="6" cy="50" r="6">
              <animateTransform
                attributeName="transform"
                dur="1s"
                type="translate"
                values="0 15 ; 0 -15; 0 15"
                repeatCount="indefinite"
                begin="0.1"
              />
            </circle>
            <circle stroke="none" cx="30" cy="50" r="6">
              <animateTransform
                attributeName="transform"
                dur="1s"
                type="translate"
                values="0 10 ; 0 -10; 0 10"
                repeatCount="indefinite"
                begin="0.2"
              />
            </circle>
            <circle stroke="none" cx="54" cy="50" r="6">
              <animateTransform
                attributeName="transform"
                dur="1s"
                type="translate"
                values="0 5 ; 0 -5; 0 5"
                repeatCount="indefinite"
                begin="0.3"
              />
            </circle>
          </svg>
        </div>
      )}
    </div>
  );
};
