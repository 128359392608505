import React, { lazy, PropsWithChildren, Suspense } from "react";

const Provider = lazy(() => import("./CookiesProvider"));

const CookiesWrapper = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<>{children}</>}>
    <Provider>{children}</Provider>
  </Suspense>
);

export default CookiesWrapper;
