"use client";

export const BaseUrl = process.env.NEXT_PUBLIC_REDIRECT_URI!;
export const BaseUrlDefault = "https://folikoinsights.com";
export const ApiBaseUrl = process.env.NEXT_PUBLIC_WORKBENCH_BASEURL!;
export const ForkUrl = process.env.NEXT_PUBLIC_FORK_BASEURL!;
export const isProd = process.env.NEXT_PUBLIC_STAGE === "prod";
export const isDevEnv = process.env.NODE_ENV === "development";
export const isLocalhost =
  typeof window !== "undefined" && window.location.host.includes("localhost");
export const RecaptchaSiteKey = "6LeAsIgoAAAAALOyUvy3H2uO0OoqkWSQKN9UXt6u"; // Public key
export const FingerprintKey = process.env.NEXT_PUBLIC_FINGERPRINT_KEY!;
