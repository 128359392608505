import { createSlice } from "@reduxjs/toolkit";

export const makeDefaultSlice = <T>(initialState?: Partial<T>) => {
  const slice = createSlice({
    initialState: (initialState ?? {}) as T,
    name: "defaultSlice",
    reducers: {},
  });
  return slice.reducer;
};
